@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@200;300;400;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Raleway:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Sofia+Sans:ital,wght@0,400..800;1,400..800&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  @apply m-0;
  transition: background-color 0.25s, border-color 0.25s;
}

@media screen and (max-width: 640px) {
  body {
    zoom: 70%;
  }

  .no-zoom {
    zoom: calc(100 / 70);
  }

  .zoom {
    zoom: 70%;
  }
}

body {
  @apply bg-black;
}

.landing-bg {
  background: rgb(87, 89, 39);
  background: linear-gradient(
    51deg,
    rgb(51, 52, 23) 0%,
    rgb(6, 36, 66) 44%,
    #000000 79%
  );
  background-attachment: fixed;
}

.landing-bg {
  background: #ffd86d;
  background: radial-gradient(
    circle,
    #ffd86d75 0%,
    rgba(0, 0, 0, 0) 75%,
    rgba(0, 0, 0, 0) 100%
  );
}

.landing-button-bg {
  background: rgb(22, 28, 46);
  background: linear-gradient(-30deg, #ffc300 0%, #ffecad 100%);
}

.pricing-modal::before {
  @apply content-[''] absolute inset-0 bg-black/10;
}

.theme-body {
  @apply text-gray-base dark:text-dark-gray-base;
}

.softer-text {
  @apply text-shade-medium-dark dark:text-shade-medium;
}

.nav-selected-text {
  @apply text-primary-light-blue !important;
}

.landing div {
  @apply text-white font-landing;
}

body {
  @apply m-0 font-sans;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  @apply font-sans-header;
}

h1:not(.landing h1) {
  @apply text-xl font-semibold;
}

.landing h1 {
  @apply font-bold text-white;
}

h6 {
  @apply text-xs font-bold uppercase;
}

hr {
  @apply border-b-gray-base dark:border-dark-gray-base border-0 border-b;
}

p {
  @apply font-normal;
}

ul {
  margin: 0;
  list-style-type: none;
}

a {
  color: inherit;
  text-decoration: inherit;
}

button,
input[type="submit"],
input[type="reset"] {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.rounded-full {
  border-radius: 50%;
}

.site-shadow {
  box-shadow: 0px 0px 0px 1px rgba(18, 18, 23, 0.1),
    0px 24px 48px 0px rgba(18, 18, 23, 0.03),
    0px 10px 18px 0px rgba(18, 18, 23, 0.03),
    0px 5px 8px 0px rgba(18, 18, 23, 0.01),
    0px 2px 4px 0px rgba(18, 18, 23, 0.01);
}

.dark-site-shadow {
  box-shadow: 0px 0px 0px 1px rgba(255, 255, 255, 0.1),
    0px 2px 4px 0px rgba(255, 255, 255, 0.04);
}

/* Ant Design Elements */

.ant-select-dropdown
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  @apply bg-gray-medium/25; /* Doesn't work: bg-gray-medium-light dark:bg-dark-gray-medium-light; */
}

/* .ant-select-multiple.ant-select-lg .ant-select-selection-item {
  @apply bg-gray-medium-light dark:bg-dark-gray-medium-light;
} */

.ant-select-multiple.ant-select-lg .ant-select-selection-overflow {
  @apply flex flex-row flex-nowrap overflow-auto;
}

/* .ant-select-dropdown,
.ant-select-item-option-content,
.ant-select-multiple.ant-select-lg .ant-select-selection-search-input,
.ant-select-multiple.ant-select-lg .ant-select-selection-search-mirror,
.ant-select-selection-item,
.ant-select-selection-placeholder,
.ant-select-selection-item-content,
.ant-tooltip,
.ant-input-number-group-addon,
.ant-input-group-addon,
.ant-input-number-input,
.ant-input,
.ant-checkbox-wrapper {
  @apply font-sans;
} */

.ant-input,
.ant-input-number-input,
.ant-select-selection-placeholder,
.ant-select-selection-item-content,
.ant-select-item-option-content {
  @apply font-normal;
}

:where(.css-dev-only-do-not-override-196asax).ant-input-number
  .ant-input-number-input,
:where(.css-196asax).ant-input-number .ant-input-number-input {
  @apply text-base;
}

.ant-picker-dropdown * {
  color: unset;
  @apply font-sans;
}

.ant-picker-input *,
.ant-dropdown *,
.ant-table-cell {
  @apply font-sans;
}

.ant-btn {
  @apply uppercase;
}

.ant-pagination {
  @apply items-center;
}

.ant-pagination-options {
  @apply mx-5;
}

.ant-table,
.ant-table .ant-table-cell,
.ant-table-placeholder,
.ant-table-wrapper:not(.filled-header) .ant-table .ant-table-header {
  @apply bg-transparent !important;
}

.filled-header .ant-table .ant-table-header {
  @apply bg-white dark:bg-dark-white;
}

/* .ant-table .ant-table-header {
  @apply bg-white dark:bg-black !important;
} */

.ant-table-row:hover {
  @apply bg-black/[3%] !important;
}

.ant-table-thead .ant-table-cell {
  @apply border-0 border-y rounded-none border-solid border-black/10 dark:border-[#303030] !important;
}

.no-top .ant-table-thead .ant-table-cell {
  @apply border-0 border-b rounded-none border-solid border-black/10 dark:border-[#303030] !important;
}

.ant-table-header {
  @apply rounded-none !important;
}

.ant-dropdown-menu {
  @apply max-h-screen overflow-y-auto !important;
}

.ant-tabs-nav::before {
  @apply border-black/10 dark:border-[#303030] !important;
}

.ant-tabs-nav {
  @apply mb-0 !important;
}

.ant-tabs-tab {
  @apply ml-0 !important;
  color: inherit !important;
}

.ant-tabs-nav-list {
  @apply flex flex-row gap-3;
}

:where(.css-dev-only-do-not-override-196asax).ant-dropdown
  .ant-dropdown-menu
  .ant-dropdown-menu-item-selected,
:where(.css-dev-only-do-not-override-196asax).ant-dropdown-menu-submenu
  .ant-dropdown-menu
  .ant-dropdown-menu-item-selected,
:where(.css-dev-only-do-not-override-196asax).ant-dropdown
  .ant-dropdown-menu
  .ant-dropdown-menu-submenu-title-selected,
:where(.css-dev-only-do-not-override-196asax).ant-dropdown-menu-submenu
  .ant-dropdown-menu
  .ant-dropdown-menu-submenu-title-selected,
:where(.css-196asax).ant-dropdown
  .ant-dropdown-menu
  .ant-dropdown-menu-item-selected,
:where(.css-196asax).ant-dropdown-menu-submenu
  .ant-dropdown-menu
  .ant-dropdown-menu-item-selected,
:where(.css-196asax).ant-dropdown
  .ant-dropdown-menu
  .ant-dropdown-menu-submenu-title-selected,
:where(.css-196asax).ant-dropdown-menu-submenu
  .ant-dropdown-menu
  .ant-dropdown-menu-submenu-title-selected {
  color: inherit;
}

.ant-select-item-option-content {
  color: inherit;
}

/* light mode table row cell selected, hover */
:where(.css-dev-only-do-not-override-1j8t1ej).ant-table-wrapper
  .ant-table-tbody
  > tr.ant-table-row-selected:hover
  > td,
:where(.css-1j8t1ej).ant-table-wrapper
  .ant-table-tbody
  > tr.ant-table-row-selected:hover
  > td {
  @apply bg-blue-medium-light;
}

/* light mode table row cell selected */
:where(.css-dev-only-do-not-override-1j8t1ej).ant-table-wrapper
  .ant-table-tbody
  > tr.ant-table-row-selected
  > td,
:where(.css-1j8t1ej).ant-table-wrapper
  .ant-table-tbody
  > tr.ant-table-row-selected
  > td {
  @apply bg-blue-light;
}

/* dark mode table row cell selected, hover */
:where(.css-dev-only-do-not-override-4omzho).ant-table-wrapper
  .ant-table-tbody
  > tr.ant-table-row-selected:hover
  > td,
:where(.css--4omzho).ant-table-wrapper
  .ant-table-tbody
  > tr.ant-table-row-selected:hover
  > td {
  @apply bg-dark-blue-strong;
}

/* dark mode table row cell selected */
:where(.css-dev-only-do-not-override-4omzho).ant-table-wrapper
  .ant-table-tbody
  > tr.ant-table-row-selected
  > td,
:where(.css-4omzho).ant-table-wrapper
  .ant-table-tbody
  > tr.ant-table-row-selected
  > td {
  @apply bg-dark-blue-mild;
}

.ant-table-footer {
  @apply bg-transparent !important;
}

/* primary button */
.ant-btn-primary {
  @apply text-gray-base;
}

/* dangerous button */
/* .ant-btn-dangerous {
  @apply text-white;
} */

.ant-select-text-base .ant-select-selection-item {
  @apply text-base;
}

.ant-form-item-required {
  @apply font-sans;
}

/* select selection */
.ant-select-multiple.ant-select-lg .ant-select-selection-overflow {
  @apply flex-wrap;
}

/* collapse content */
.ant-collapse-content-box {
  @apply p-0 !important;
}

.ant-collapse-content {
  @apply bg-transparent !important;
}

/* Various elements */

ol,
li {
  font-weight: inherit;
  @apply my-2;
}

button {
  @apply uppercase;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}
/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* Landing main page header text background */
@keyframes color-wave {
  0% {
    background-color: #ab69ff;
  }
  33% {
    background-color: #2bd9ff;
  }
  67% {
    background-color: #ffd02b;
  }
  100% {
    background-color: #ab69ff;
  }
}

.color-wave-text {
  animation: color-wave 5s infinite ease-in-out;
}

/* Wave background */
@keyframes left-right {
  0% {
    margin-left: -48%;
  }
  50% {
    margin-left: 0;
  }
  100% {
    margin-left: -48%;
  }
}

.left-right-animation {
  animation: left-right 30s infinite ease-in-out;
}
